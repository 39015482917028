/**
 * WordPress dependencies
 */
import { SVG, Path } from '@wordpress/primitives';

const justifyCenterVertical = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path d="M20 11h-5V4H9v7H4v1.5h5V20h6v-7.5h5z" />
	</SVG>
);

export default justifyCenterVertical;
