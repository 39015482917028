/**
 * WordPress dependencies
 */
import { SVG, Path } from '@wordpress/primitives';

const justifyBottom = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path d="M15 4H9v11h6V4zM4 18.5V20h16v-1.5H4z" />
	</SVG>
);

export default justifyBottom;
